import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Error, {
      icon: _ctx.NotFoundIcon,
      message: "There is an error on our side",
      subtext: "Please reload this page or try again later.",
      link: "/dashboard",
      linkText: "Go To Mekari Developer Center"
    }, null, 8, ["icon"])
  ]))
}